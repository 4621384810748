<template>
  <el-dialog title="领料" width="1100px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <div class="f-bold m-b-10">所需物料</div>
      <vxe-grid
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :merge-cells="mergeCell"
        :columns="tableColumn1"
        :data="selfDetail.need_list"
      >
      </vxe-grid>
      <div class="f-bold m-y-10">领料</div>
      <vxe-grid
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :edit-rules="validRules"
        :columns="tableColumn2"
        :data="selfDetail.ling_liao_list"
      >
        <!-- slot_default -->
        <template #default_ling_liao_count="{ row }">
          <span v-if="JSON.stringify(row.ling_liao_count)">{{ row.ling_liao_count }}</span>
          <span v-else class="c-gray-light">请输入</span>
        </template>
        <template #default_ling_liao_ren="{ row }">
          <span v-if="row.ling_liao_ren">{{ row.ling_liao_ren }}</span>
          <span v-else class="c-gray-light">自动生成</span>
        </template>
        <template #default_ling_liao_date="{ row }">
          <span v-if="row.ling_liao_date">
            {{ row.ling_liao_date ? row.ling_liao_date.slice(0, 10) : '' }}
          </span>
          <span v-else class="c-gray-light">自动生成</span>
        </template>
        <template #default_status="{ row }">
          <span :class="row.status === '已出库' ? 'c-green' : 'c-gray-light'">
            {{ row.status }}
          </span>
        </template>
        <template #default_chu_ku_code="{ row }">
          <span v-if="row.chu_ku_code">{{ row.chu_ku_code }}</span>
          <span v-else class="c-gray-light">自动生成</span>
        </template>

        <!-- edit -->
        <template #edit_ling_liao_count="{ row }">
          <el-input
            v-model="row.ling_liao_count"
            size="mini"
            placeholder="请输入"
            clearable
            :disabled="row.status === '已出库'"
          />
        </template>

        <!-- 操作 -->
        <template #default_do="{ row, rowIndex }">
          <el-link class="m-r-10" type="success" @click="rowAdd">增行</el-link>
          <el-link
            v-if="row.status !== '已出库'"
            class="m-r-10"
            type="danger"
            @click="rowDelete(rowIndex)"
          >
            删行
          </el-link>
        </template>

        <!-- 数据为空 -->
        <template #empty>
          <div class="flex flex-center">
            <span class="f-s-14 c-gray-light">暂无数据</span>
            <el-link class="m-l-10" type="success" @click="rowAdd">增行</el-link>
          </div>
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      mergeCell: [],
      selfDetail: {
        data: {},
        need_list: [],
        ling_liao_list: []
      },
      tableColumn1: [
        {
          title: '物料号',
          field: 'wu_liao_code',
          isMerge: true
        },
        {
          title: '品名',
          field: 'craft',
          isMerge: true
        },
        {
          title: '材质',
          field: 'texture',
          isMerge: true
        },
        {
          title: '规格',
          field: 'sales_specification',
          isMerge: true
        },
        {
          title: '单位',
          field: 'unit',
          isMerge: true
        },
        {
          title: '数量',
          field: 'counts'
        },
        {
          title: '仓库',
          field: 'cang_ku'
        }
      ],
      tableColumn2: [
        {
          title: '领料数量',
          field: 'ling_liao_count',
          slots: { default: 'default_ling_liao_count', edit: 'edit_ling_liao_count' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '领料人',
          field: 'ling_liao_ren',
          slots: { default: 'default_ling_liao_ren' }
        },
        {
          title: '领料日期',
          field: 'ling_liao_date',
          slots: { default: 'default_ling_liao_date' }
        },
        {
          title: '状态',
          field: 'status',
          slots: { default: 'default_status' }
        },
        {
          title: '出库单号',
          field: 'chu_ku_code',
          slots: { default: 'default_chu_ku_code' }
        },
        {
          title: '操作',
          slots: { default: 'default_do' }
        }
      ],
      validRules: {
        ling_liao_count: [{ required: true }]
      }
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/gongDan/getRenWu',
        params: {
          ren_wu_id: this.data.ren_wu_id,
          type: '领料'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfDetail = this.$util.deepCopy(res.data)
          if (!this.selfDetail.ling_liao_list.length) this.rowAdd() // 数据为空，默认补一行
          this.mergeCell = this.$tool.getMergeCellConfigList(
            this.selfDetail.need_list,
            this.tableColumn1,
            'wu_liao_code'
          )
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush() {
      let isPassRenWuCount = this.selfDetail.ling_liao_list.every(
        (item) => !!Number(item.ling_liao_count)
      )
      if (!isPassRenWuCount) {
        this.$message.error('领料数量必须>0')
        return
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/gongDan/saveOrUpdateRenWu',
        data: this.selfDetail
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 行-增行
    rowAdd() {
      let result = {
        ling_liao_count: '',
        ling_liao_ren: '',
        ling_liao_date: '',
        chu_ku_code: ''
      }
      this.selfDetail.ling_liao_list.push(result)
    },
    // 行-删行
    rowDelete(index) {
      this.selfDetail.ling_liao_list.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped></style>
